/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    ol: "ol",
    li: "li",
    code: "code",
    h2: "h2",
    h3: "h3",
    pre: "pre",
    strong: "strong"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "If I were a cybercriminal, software developers would be my favorite target. First of all, if they are open source maintainers, they almost always store package repository access tokens as plain unsecured files. Gaining access to these files would allow an attacker to publish a new version of an open source library with an addition of malicious code that will be run on all machines that download the new version and to which the code using the new version is deployed. This is not just a theoretical threat – such attack was launched on numerous occasions, ", React.createElement(_components.a, {
    href: "https://nodesource.com/blog/a-high-level-post-mortem-of-the-eslint-scope-security-incident/"
  }, "eslint-scope being the most prominent one"), "."), "\n", React.createElement(_components.p, null, "Of course not everyone is a maintainer of a notable open source library, but they can still be an attractive target. Developers tend to have access to production systems – if the systems are managed via the command-line utilities (such as Heroku), the credentials are stored as unsecured files, and stealing the files would result in gaining access to their systems."), "\n", React.createElement(_components.p, null, "The problem is serious and well-known. This is one of the reasons you ", React.createElement(_components.a, {
    href: "https://stackoverflow.com/a/3818909"
  }, "added a passphrase"), " to our SSH keys. NPM already ", React.createElement(_components.a, {
    href: "https://docs.npmjs.com/about-two-factor-authentication"
  }, "implemented two-factor authentication "), " in their CLI, while RubyGems maintainers ", React.createElement(_components.a, {
    href: "https://github.com/rubygems/rubygems.org/issues/1252#issuecomment-404605458"
  }, "actively work on adding 2FA"), " to the platform."), "\n", React.createElement(_components.p, null, "What about the other files? Here are typical files with access credentials:"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "~/.netrc"), " — standard Unix location storing data for logging in to remote hosts"), "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "~/.aws/credentials"), " — Amazon Web Services credentials are used by a number of tools, Serverless.js being the most notable one"), "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "~/.heroku/")), "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "~/.eyrc"), " — Engine Yard"), "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "~/.s3cfg"), " — s3cmd"), "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "~/.gmvault/gmvault_defaults.conf")), "\n"), "\n", React.createElement(_components.h2, null, "How exactly is it a threat?"), "\n", React.createElement(_components.p, null, "As developers, we run third-party code all the time. I'm pretty sure you did not audit every single line of your dependencies. Even running ", React.createElement(_components.code, null, "yarn"), " / ", React.createElement(_components.code, null, "bundle install"), " to install dependencies is potentially dangerous as one of the libraries could have a malicious post-install script that is automatically executed by package managers. One doesn't even need to publish a malicious version of a notable open-source library – ", React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Typosquatting"
  }, "typosquatting"), " a popular library is another way to execute arbitrary code on developer's machines."), "\n", React.createElement(_components.h2, null, "Is there a solution?"), "\n", React.createElement(_components.p, null, "Waiting for all CLI utilities to implement two-factor authentication is unrealistic so we have to take matters to our own hands. My recommendation is to have two accounts on your operating system:"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "Regular account the does all the development work. This account would also install all dependencies."), "\n", React.createElement(_components.li, null, "Separate account that would only store credentials and deploy. This account should never install anything (the deployment tools should be installed by your regular account) or do anything other than deployment."), "\n"), "\n", React.createElement(_components.p, null, "The rest of the article assumes using macOS but the idea is the same on other operating systems."), "\n", React.createElement(_components.h3, null, "Step 1: multi-user homebrew setup"), "\n", React.createElement(_components.p, null, "The deployer should have access to homebrew to run all utilities installed by the regular user. The most elegant solution is to create a separate ", React.createElement(_components.code, null, "homebrew"), " group:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-bash"
  }, "sudo dscl . create /Groups/homebrew\nsudo dscl . create /Groups/homebrew RealName \"brew.sh users\"\nsudo dscl . create /Groups/homebrew gid 599\nsudo dscl . create /Groups/homebrew GroupMembership $(whoami)\nsudo chgrp -R homebrew $(brew --prefix)/*\nsudo chmod -R g+rwX $(brew --prefix)/*\n")), "\n", React.createElement(_components.p, null, "The number 599 is just an example, run ", React.createElement(_components.code, null, "dscl . list /Groups PrimaryGroupID"), " and pick a number that is not used by any other group."), "\n", React.createElement(_components.h3, null, "Step 2: create the user"), "\n", React.createElement(_components.p, null, "Make sure to run ", React.createElement(_components.code, null, "dscl . list /Users UniqueID"), " to select a number that is not taken by any other user. In the below example, we'll use 499:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-bash"
  }, "sudo dscl . create /Users/deploy UniqueID 499\nsudo dscl . create /Users/deploy PrimaryGroupID 20 # staff\nsudo dscl . create /Users/deploy UserShell /bin/bash\n\n# optional, if you prefer a non-standard location\nsudo dscl . create /Users/deploy NFSHomeDirectory /var/deploy\n\nsudo dscl . append /Groups/homebrew GroupMembership deploy\nsudo chown -R deploy /var/deploy\n")), "\n", React.createElement(_components.h3, null, "Step 3: shell configuration"), "\n", React.createElement(_components.p, null, "Run ", React.createElement(_components.code, null, "su deploy"), " and edit ", React.createElement(_components.code, null, "~/.bashrc"), ":"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-bash"
  }, "# Add homebrew to PATH\nexport PATH=/usr/local/bin:$PATH\n# Let's have a distinct prompt\nexport PS1=\"\\e[1;30m\\][\\[\\e[1;34m\\]\\u@\\h\\[\\e[1;30m\\] \\[\\e[1;33m\\]\\w\\[\\e[0;37m] \\n\\$ \"\n# We'll be using Rubies and gems installed by the regular user\nexport RBENV_ROOT=/Users/---your-regular-user-name---/.rbenv\n# Initialize rbenv\neval \"$(rbenv init -)\"\n")), "\n", React.createElement(_components.h3, null, "Step 4: move your credentials to deployer's home directory."), "\n", React.createElement(_components.p, null, "It wouldn't hurt to test the credentials. Also, try to access the credentials from the regular user's account: you ", React.createElement(_components.strong, null, "should"), " have permission denied error."), "\n", React.createElement(_components.h3, null, "Step 5: use deployer account to deploy"), "\n", React.createElement(_components.p, null, "From now on, use only the deployer account to deploy, sync files etc."), "\n", React.createElement(_components.h2, null, "Conclusion"), "\n", React.createElement(_components.p, null, "As developers, we install and run third-party code all the time. Don't let a malicious post-install script steal your production access credentials."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
